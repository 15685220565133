import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import dog_service from '../Assets/dog_service.png';
import cat_service from '../Assets/cat_service.png';
import paw from '../Assets/paw.png';
import dogImage from '../Assets/dog_running_in_yard.png';


const StyledBox = styled('div')(({ theme }) => ({
    alignSelf: 'center',
    width: '100%',
    height: 600,
    marginTop: '120px',
    paddingTop: '24px',
    borderColor: theme.palette.grey[200],
    boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
    backgroundImage: `url(${dogImage})`,
    backgroundSize: 'cover',
    transform: 'scaleX(-1)',
  }));

const items = [
    {
        icon: <img width='100px' src={dog_service} alt='Dog Services' 
                style={{
                    marginRight: '15px'
                }}
                />,
        title: 'Dog Poop Removal',
        description: ['Removal of all dog waste in the yard.', 'Sanitized equipment between every job to ensure your dogs health.', 'Proof of a secure yard at the completion of every service.', 'Service options available weekly, biweekly, and monthly.'],

    },
    {
        icon: <img width='100px' src={cat_service} alt='Cat Services' 
        style={{
            marginRight: '15px'
        }}
        />,
        title: 'Kitty Litter Exchange',
        description: ['Clean litter box exchanged for the soiled box once per week.', 'Dirty box cleaned, sanitized and refilled to be ready for the next exchange.', 'Always have a clean litter box, never worry about having to deal with cat waste.','Have as many boxes as your home needs.'],
    }
];


const Services = (props: {}) => {
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(0)
    return (
        <Container id="Services" sx={{ py: {xs: 8, sm: 16}}}>
            <Box 
                sx={{
                    margin: '-40px auto',
                    width: { xs: '100%', md: '60%'}
                }}
            >
                <Typography
                    component='h2'
                    variant='h4'
                    gutterBottom
                    sx={{ fontFamily: 'Montserrat, sans serif', fontWeight: '400',textAlign: 'center', width: '100%', fontSize: '36px', color: '#3a3a3a', margin: '0 auto'}}
                >
                    Our Services
                </Typography>
                <Typography
                    variant='body1'
                    sx={{ 
                        color: '#3a3a3a',
                        mb: { xs: 4 },
                        fontFamily: 'Montserrat, sans serif',
                        fontWeight: 400,
                    }}
                >
                    At Outscooped, we specialize in professional pet waste removal services that keep your yard clean, healthy, and hassle-free. Whether it’s a one-time cleanup or ongoing maintenance, we ensure your outdoor space is safe and enjoyable for your family and furry friends. Our eco-friendly practices, reliable scheduling, and commitment to quality mean you can count on us for a cleaner yard and more free time. Plus, we provide photo confirmations and gate security checks after every visit, so you can have peace of mind with every service.
                </Typography>
            </Box>
            <Box
                sx={{
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: { xs: 'column'},
                    gap: 2
                }}
            >
                <div>
                    <Box
                        sx={{
                            display: { xs: 'flex'},
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        {items.map((({icon, title, description}, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        p: 2,
                                        height: '100%',
                                        alignSelf: 'center',
                                        marginTop: '24px',
                                        width: {xs: '100%', md: '60%'}
                                    }}
                                >
                                    <Box sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                        gap: 1,
                                        textAlign: 'left',
                                        textTransform: 'none',
                                        color: '#3a3a3a'
                                    }}>
                                        
                                        <Typography variant="h1" sx={{
                                            fontFamily: 'Lilita One, sans serif',
                                        }}>{title}</Typography>
                                        <div style={{
                                            width: '90%',
                                            borderBottom: '1px solid #202020'
                                        }} />
                                        <Box 
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {icon}
                                            <Box 
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-evenly',
                                                height: '225px'
                                            }}>
                                            {description.map((text, i) => {
                                                return (
                                                    <Box key={i}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            height: '40px'
                                                        }}>
                                                        <img width='25px' src={paw} alt='Paw' style={{marginRight: '15px'}}/>
                                                        <Typography variant="body2" sx={{
                                                            fontFamily: 'Montserrat, sans serif',
                                                            fontWieght: 400,
                                                        }}>{text}</Typography>
                                                    </Box>
                                                )
                                            })}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                            );
                        }))}
                    </Box>
                </div>
            </Box>
            <StyledBox 
                sx={{
                    backgroundPosition: { xs: '-350px center', md: 'center center'}
                }}
            >
                <Box
                    sx={{
                        margin: { xs: '390px 20px',sm: '180px 0px'},
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: {xs:'flex-end', sm: 'flex-start'},
                        transform: 'scaleX(-1)',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#fff',
                            textAlign:'center',
                            width:'300px',
                            fontSize: {xs:'32px', sm: '48px'},
                            fontFamily: 'Montserrat, sans serif',
                            fontWeight: 600,
                        }}
                    >Happiness Starts with a Clean Yard.</Typography>
                    <Box
                        sx={{
                            marginTop: {xs: '0'},
                            dislplay: 'flex',
                            width: '300px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}>
                        <Typography
                            sx={{
                                color: '#fff',
                                fontSize: {xs:'18px', sm: '24px'},
                                fontFamily: 'Lilita One, sans serif',
                                fontWeight: 400,
                            }}
                       >Let Outscooped handle the mess so your pup can enjoy the rest.</Typography>
                    </Box>
                </Box>
            </StyledBox>
        </Container>
    );
}

export default Services;