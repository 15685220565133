import { Box, Container, Divider, Typography } from '@mui/material';
import * as React from 'react';

import logo from '../Assets/outscooped.png';
const Footer = () => {
    return (
        <Container sx={{
            width: '100%',
            height: '400px',
            backgroundColor: '#3a3a3a',
            color: '#fff',
            mt: '45px',
        }}>
            <Box sx={{
                height: '80%',
                pt: '40px',
                pl: '20px',
            }}>
                <img width={'100px'} src={logo} alt='Outscoooped' />
                <Typography
                    sx={{
                        fontFamily: 'Montserrat, sans serif',
                        fontWeight: '400',
                        fontSize: '14px',
                    }}>
                    Bringing families closer, one yard at a time.
                </Typography>
            </Box>
            <Divider />
            <Box sx={{
                pt: '30px',
                pl: '20px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <Typography
                    sx={{
                        fontFamily: 'Montserrat, sans serif',
                        fontWeight: '400',
                        fontSize: '14px',
                    }}>
                &copy;{`${new Date().getFullYear()} Outscooped `}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Montserrat, sans serif',
                        fontWeight: '400',
                        fontSize: '14px',
                    }}>
                        520-557-4066
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Montserrat, sans serif',
                        fontWeight: '400',
                        fontSize: '14px',
                    }}>
                        Maricopa, AZ
                </Typography>
            </Box>
        </Container>
    )
};

export default Footer;