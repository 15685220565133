import { Box, Button, Card, CardActions, CardContent, Chip, Container, Divider, Grid2 as Grid, Typography } from '@mui/material';
import * as React from 'react';

const tiers = [
    {
        title: 'Weekly Dog Poop Clean Up',
        price: '22.00',
        description: [
            'Our Best deal!',
            'Get Half off the Initial Cleaning.',
            'Poop Removal taken off-site included.'
        ],
        buttonText: 'Sign Me Up'
    },
    {
        title: 'Bi-Weekly Dog Poop Clean Up',
        price: '34.00',
        description: [
            '','',''
        ],
        buttonText: 'Sign Me Up'
    },
    {
        title: 'Monthy Dog Poop Clean Up',
        price: '52.50',
        description: [
            '','',''
        ],
        buttonText: 'Sign Me Up'
    },
    {
        title: 'Kitty Litter Box Exchange',
        price: '16.00',
        description: [
            'One flat rate per box exchange.',
            'Add additional boxes at $7 dollars each.',
            ''
        ],
        buttonText: 'Sign Me Up'
    }
];
const Pricing = () => {
    return (
        <Container id='Pricing'
        sx={{
            mt: '100px',
            paddingTop: '100px'
        }}
        >
             <Box
        sx={{
          margin: '0 auto 20px auto',
          width: { xs: '100%', sm: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: '#3a3a3a', fontFamily: 'Montserrat, sans serif', fontWeight: 400, fontSize: '36px' }}
        >
          Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: '#3a3a3a', fontFamily: 'Montserrat, sans serif', fontSize: '18px' }}>
            One flat rate per clean up, no rate increases for number of dogs, or size of yard. Poop Removal off-site for free no additional charge to customer. All dog poop clean up options will require an initial clean up fee of $55.00.
        </Typography>
      </Box>
           <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 8, sm: 8, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '425px',
                },
                tier.title === 'Weekly Dog Poop Clean Up' &&
                  ((theme) => ({
                    border: 'none',
                    background:
                      'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                    boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                  })),
              ]}
            >
              <CardContent>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'center',
                    },
                    tier.title === 'Weekly Dog Poop Clean Up'
                      ? { color: 'grey.100' }
                      : { color: '' },
                  ]}
                >
                  <Typography component="h3" variant="h6" sx={{
                    fontFamily: 'Montserrat, sans serif',
                    fontWeight: '700',
                    width: '80%',
                    fontSize: '16px',
                    textAlign: 'center'
                  }}>
                    {tier.title}
                  </Typography>
                </Box>
                <Box
                  sx={[
                    {
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'center',
                    },
                    tier.title === 'Weekly Dog Poop Clean Up'
                      ? { color: 'grey.50' }
                      : { color: null },
                  ]}
                >
                    <Box sx={{ display: 'flex', flexDirection: {xs: 'column'}, alignItems: 'center'}}>
                        <Typography component="h3" variant="h2"
                            sx={{
                                color:"#6CAF46",
                                fontFamily: 'Montserrat, sans serif',
                                fontWieght: '600'
                            }}>
                            ${tier.price}
                        </Typography>
                        <Typography component="h3" variant="h6"
                            sx={{
                                fontFamily: 'Montserrat, sans serif',
                                fontWeight: 400,
                            }}
                        >
                            &nbsp; per cleaning 
                        </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
              </CardContent>
              <CardActions
                sx={{
                    justifyContent: 'space-between',
                    height: '100%',
                    flexDirection: 'column',
                }}
              >
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    flexWarp: 'no-wrap',
                }}>
                    {tier.description.map((text, i) => {
                        return (
                        <Typography sx={[
                            {
                            fontFamily: 'Montserrat, san serif',
                            width: '100%',
                            fontSize: '20px',
                            textAlign: 'left',
                            color: '#3a3a3a',
                            padding: 0,
                        },
                        tier.title == "Weekly Dog Poop Clean Up" &&
                        ((theme) => ({
                          color: '#fff',
                        }))
                        ]
                        } 
                        key={i} 
                        component={'span'}
                        >{text}</Typography>);
                    })}
                </Box>
                <Button
                  fullWidth
                  variant={'contained'}
                  sx={{backgroundColor:'#6CAF46', 
                    alignSelf: 'flex-end',
                  }}
                  onClick={()=>{ window.location.href = 'https://quote.outscooped.com'}}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
        </Grid> 
        </Container>
    );
}

export default Pricing;