import * as React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography } from '@mui/material';

const faqs = [
    {
        category: 'General Questions',
        categoryFAQs: [
            {
                question: 'What services do you provide?',
                answer: 'We offer professional pet waste removal services, including regular yard cleanups, to keep your yard clean and healthy.',
            },
            {
                question: 'How does the service work?',
                answer: 'Our team will visit your property on a scheduled day, clean up all pet waste, and ensure the yard is left spotless. We also secure your gate and provide photo confirmation after every visit.',
            },
            {
                question: 'Do I need to be home for the service?',
                answer: 'No, you don’t need to be home. As long as we have access to your yard, we’ll take care of everything.',
            },
            {
                question:'How do I schedule or reschedule a service?',
                answer:'You can schedule or reschedule a service easily through our website or by contacting us directly.'
            }
        ]
    },
    {
        category: 'Safety and Sanitation',
        categoryFAQs: [
            {
                question: 'How do you ensure sanitation between yards?',
                answer: 'We sanitize all tools and equipment between jobs to prevent contamination and keep your yard safe and healthy.'
            },
            {
                question: 'Is your service safe for my pets?',
                answer: 'Absolutely! We use eco-friendly methods and make sure gates are secured after every visit to keep your pets safe.'
            }
        ]
    },
    {
        category: 'Pricing and Payment',
        categoryFAQs: [
            {
                question: 'How much does your service cost?',
                answer: 'Our pricing is a fixed rate. Number of dogs and size of yard does not change the price. Give us a call for more details 520-557-4066.'
            },
            {
                question: 'What payment methods do you accept?',
                answer: 'We accept all major credit cards and online payments for your convenience.'
            },
            {
                question: 'Are there discounts for regular service?',
                answer: 'Yes! We offer a discount on your initial cleaning of 50% when signing up for a weekly recurring service.'
            }
        ]
    },
    {
        category: 'Additional Questions',
        categoryFAQs: [
            {
                question: 'What areas do you serve?',
                answer: 'We currently serve the Pinal County area of Arizona, this includes Maricopa, Casa Grande, Eloy, Arizona City, Tucson and its greater metro area.'
            },
            {
                question: 'Can you clean up after multiple dogs?',
                answer: 'Yes, we can handle waste from multiple pets.'
            },
            {
                question: 'What happens if there’s bad weather?',
                answer: 'We’ll do our best to complete the service as scheduled. In extreme weather, we’ll notify you and reschedule if necessary.'
            },
            {
                question: 'Do you offer any guarantees?',
                answer: 'We guarantee satisfaction with every cleanup. If you’re not happy, let us know, and we’ll make it right!'
            }
        ]
    }
]
const FAQ = () => {
    const [expanded, setExpanded] = React.useState<string|false>(false);

    const handleChange =
        (panel: string)  => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        }
    return (
        <Container
            id="FAQ"
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: {xs: '100%', md: '60%'},
                mt: '80px',
            }}
        >
            <Typography
                component="h2"
                variant='h4'
                sx={{
                    color: '#3a3a3a',
                    width: { sm: '100%', md: '80%'},
                    textAlign: { sm: 'left', md: 'center'},
                    fontFamily: 'Montserrat, sans serif',
                    fontWeight: '600',
                    fontSize: '36px',
                }}
            >
                Frequently Asked Questions
            </Typography>
            { faqs.map(({category, categoryFAQs}, index) => {
                return (
                    <>
                    <Box key={index} sx={{
                        fontFamily: 'Montserrat, sans serif',
                        fontWeight: 600,
                        fontSize: '24px',
                        pt: '48px',
                        pb: '10px',
                    }}>{category}</Box>
                    {categoryFAQs.map(({question, answer}, i) => {
                        return (
                            <Box key={i} sx={{ width: '100%'}}>
                                <Accordion
                                    expanded={expanded === `panel${index}${i}`}
                                    onChange={handleChange(`panel${index}${i}`)}
                                >
                                    <AccordionSummary 
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`panel${index}${i}d-content`}
                                        id={`panel${index}${i}d-header`}
                                    >
                                        <Typography component='h3' variant='subtitle2'
                                            sx={{
                                                fontFamily: 'Montserrat, sans serif',
                                                fontWeight: 400
                                            }}>
                                            {question}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant='body2'
                                            gutterBottom
                                            sx={{
                                                fontFamily: 'Montserrat, sans serif',
                                                fontWeight: 400,
                                                maxWidth: { sm: '100%', md: '70%'}
                                            }}
                                        >
                                           { answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        )
                    })}
                    </>
                )
            })}
            
            
        </Container>
    )
}

export default FAQ;