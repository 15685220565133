import * as React from 'react';
import AppTheme from './theme/AppTheme';
import { CssBaseline } from '@mui/material';
import Navbar from './Components/navbar/Navbar';
import Hero from './Components/Hero';
import Services from './Components/Services';
import About from './Components/About';
import Pricing from './Components/Pricing';
import FAQ from './Components/FAQ';
import SignUp from './Components/SignUp';
import Footer from './Components/Footer';

function App(props: {disableCustomTheme?: boolean}) {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <Navbar />
      <Hero />
      <Services />
      <Pricing />
      <About />
      <FAQ />
      <Footer />
    </AppTheme>
  );
}

export default App;
