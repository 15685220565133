import * as React from 'react';
import { Box, Container, Typography, Stack, InputLabel, Link, TextField, Button } from '@mui/material';
import { visuallyHidden} from '@mui/utils';
import {styled} from '@mui/material/styles';
import heroImage from '../Assets/heroImage.png';

const StyledBox = styled('div')(({ theme }) => ({
    alignSelf: 'center',
    width: '100%',
    height: 600,
    marginTop: '120px',
    paddingTop: '24px',
    borderColor: theme.palette.grey[200],
    boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
  }));

const Hero = (props:{}) => {
    return (
        <Box
            id="hero"
            sx={(theme) => ({
            width: '100%',
            backgroundRepeat: 'no-repeat',
    
            backgroundImage:
                'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
            ...theme.applyStyles('dark', {
                backgroundImage:
                'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
            }),
            })}
        >
            <StyledBox id="image" sx={{ backgroundPosition: {xs: 'cover', md: 'center'}}}>
                <Box
                    sx={{
                        margin: { xs: '50px 20px', md: '50px -50px'},
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: {xs:'flex-start', md: 'flex-end'},
                    }}
                >
                    <Typography
                        sx={{
                            textAlign:'center',
                            width:'300px',
                            fontSize: {xs:'32px', md: '48px'},
                            fontFamily: 'Montserrat, sans serif',
                            fontWeight: 400,
                        }}
                    >More Time,</Typography>
                     <Typography
                        sx={{
                            textAlign:'center',
                            width:'300px',
                            fontSize: {xs:'32px', md: '48px'},
                            fontFamily: 'Montserrat, sans serif',
                            fontWeight: 400,
                        }}
                    >More Joy,</Typography>
                     <Typography
                        sx={{
                            textAlign:'center',
                            width:'300px',
                            fontSize: {xs:'32px', md: '48px'},
                            fontFamily: 'Montserrat, sans serif',
                            fontWeight: 400,
                        }}
                    >Less Mess.</Typography>
                    <Box
                        sx={{
                            marginTop: {xs: '180px', md: 0},
                            dislplay: 'flex',
                            width: '300px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}>
                        <Typography
                            sx={{
                                color: '#3a3a3a',
                                fontSize: {xs:'24px', md: '36px'},
                                fontFamily: 'Montserrat, sans serif',
                                fontWeight: 900,
                            }}
                        >OUTSCOOPED</Typography>
                        <Typography
                            sx={{
                                color: '#6CAF46',
                                fontSize: {xs:'18px', md: '24px'},
                                fontFamily: 'Lilita One, sans serif',
                                fontWeight: 500,
                            }}
                        >Pet Poop Cleanup</Typography>
                        <Box
                            sx ={{
                                width: '100%',
                                height: '100px',
                                marginTop: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: {xs: 'space-evenly', md:'space-between'},
                                alignItems: {xs:'center'},
                            }}>
                            <Button
                                sx={{
                                    width: '180px',
                                    fontSize: '24px',
                                    backgroundColor: '#6CAF46',
                                    size: { xs: 'small', md:'large'},
                                    fontFamily: 'Montserrat, sans serif',
                                    fontWeight: '400',
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => { window.location.href ='https://quote.outscooped.com'}}
                                
                            >Sign Me Up</Button>
                            <Button
                                sx={{
                                    backgroundColor: '#3a3a3a',
                                    width: '120px',
                                    fontSize: '10px',
                                    size: { xs: 'small'},
                                    fontFamily: 'Montserrat, sans serif',
                                    fontWeight: '400',
                                }}
                                onClick={() => { window.location.href ='/#Services'}}
                                color="secondary"
                                variant="contained"
                                
                            >Learn More</Button>
                        </Box>
                    </Box>

                </Box>
                
            </StyledBox>
            <Box>
                <Typography sx={{
                    fontFamily: 'Montserrat, sans serif',
                    fontSize: {xs: '21px', sm: '28px', md:'42px'},
                    fontWeight: 400,
                    textAlign: 'center'
                }}>
                    Southern Arizona's Premier Poop Scooper
                </Typography>
            </Box>
            <Container
                sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: '-40px',
                pt: { xs: 14, sm: 20 },
                pb: { xs: 8, sm: 12 },
                }}
            >
                <Stack
                spacing={2}
                useFlexGap
                sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
                >
                <Typography
                    variant="h1"
                    sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'column' },
                    alignItems: 'center',
                    fontSize: {xs: '32px', md:'42px'},
                    fontFamily: '"Lilita One", sans serif',
                    fontWeight: 400,
                    }}
                >
                    More Time for What You Love,&nbsp;
                    <Typography
                    component="span"
                    variant="h1"
                    sx={(theme) => ({
                        fontSize: 'inherit',
                        color: '#6CAF46',
                        fontFamily: '"Lilita One", sans serif',
                        fontWeight: 400,
                        ...theme.applyStyles('dark', {
                        color: 'primary.light',
                        
                        }),
                    })}
                    >
                    Less Time on Cleanup!
                    </Typography>
                </Typography>
                <Typography
                    sx={{
                    textAlign: 'center',
                    color: 'text.secondary',
                    width: { sm: '100%', md: '80%' },
                    fontSize: { sm: '18px', md: '22px'},
                    fontFamily: 'Montserrat, sans serif',
                    
                    }}
                >
                    We take the dirty work off your hands, so you can focus on what really matters—your family, your pup, and your life.
                </Typography>
                <Stack
                    direction={{ xs: 'column'}}
                    spacing={1}
                    useFlexGap
                    sx={{ pt: 2, width: { xs: '100%'} }}
                >
                    {/*<InputLabel htmlFor="email-hero" sx={visuallyHidden}>
                    Email
                    </InputLabel>
                    <TextField
                    id="email-hero"
                    hiddenLabel
                    size="small"
                    variant="outlined"
                    aria-label="Enter your email address"
                    placeholder="Your email address"
                    fullWidth
                    slotProps={{
                        htmlInput: {
                        autoComplete: 'off',
                        'aria-label': 'Enter your email address',
                        },
                    }}
                    />*/}
                    <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ 
                        fontSize: '20px',
                        minWidth: 'fit-content',
                        backgroundColor: '#6CAF46',
                        fontFamily: 'Montserrat, sans serif',
                        fontWeight: '400',
                     }}
                     onClick={() => { window.location.href ='https://quote.outscooped.com'}}
                    >
                    Schedule Your First Pickup
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{
                            fontSize: '14px',
                            minWidth: 'fit-content',
                            backgroundColor: '#3a3a3a',
                            fontFamily: 'Montserrat, sans serif',
                            fontWeight: '400',
                        }}
                        onClick={() => { window.location.href ='/#Services'}}
                        >
                    Learn More
                    </Button>
                </Stack>
                </Stack>
                
            </Container>
        </Box>
    );
}

export default Hero;